<template>
<div>
    <v-dialog
      width="500" class="pa-3 mx-auto mt-5"
      v-model="reportGenerationStarted"
      >
      <v-card>
        <v-card-text>
          <img alt="logo" src="../assets/settings.png" class="responsive" width="100">
        </v-card-text>
        <v-card-text class="text-h5 mb-1">
          We are working on it...
        </v-card-text>
        <v-card-text>
          The CSV export is being created and will be sent to <b>{{this.emailId}}</b>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ReportForm Heading= 'Dosing Report' ReportFromDateLabel= 'Dose From Date' ReportTillDateLabel= 'Dose Till Date' @submit="sendFormData($event)"/>
</div>
</template>

<script>
import ReportForm from './sharedcomponents/ReportsForm'
import { mapState, mapActions } from 'vuex'
import { adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, getUsernameAndPassword } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
import Vue from 'vue'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

export default {
  components: { ReportForm },
  data: () => ({
    reportGenerationStarted: false
  }),
  computed: {
    ...mapState([
      'emailId',
      'isProd',
      'platform',
      'filterData'
    ])
  },
  mounted: function () {
  },
  beforeUpdate: function () {
  },
  methods: {
    ...mapActions([
      'loading'
    ]),
    async sendFormData (apiPath){
      if (this.filterData === null) {
        return
      }
      const path = apiPath
      try {
        this.loading(true)
        const usernameAndPassword = getUsernameAndPassword(this.isProd)
        const data = await ApiClient.post(usernameAndPassword.Username, usernameAndPassword.Password, this.filterData, path)
        if (data.data.Success) {
          this.reportGenerationStarted = true
          adminLogData(this.$route.query.platform + ' Dosing Report', apiResponseType.SUCCESS, data.data.Success)
        }
        if (!data.data.Success) {
          this.reportGenerationStarted = true
          adminLogData(this.$route.query.platform + ' Dosing Report', apiResponseType.FAILED, data.data.Success)
          Vue.toasted.error(data.data.Error.Message, { duration: 2000 })
        }
        this.loading(false)
      } catch (ex) {
        this.loading(false)
        Vue.toasted.error(apiResponseType.FAILED, { duration: 2000 })
      }
    }
  }
}
</script>
<style scoped>
.responsive {
  align-self: center;
  margin-top: 20px;
}
</style>
